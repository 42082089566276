import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { map } from 'rxjs';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { GenAiType } from 'src/core/models/generic-lookup-type/analysis/gen-ai-type.glt';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { CategoryDto } from 'src/core/models/query/category.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { CrudService } from 'src/core/services/crud/crud.service';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';

@Component({
  selector: 'ca-open-ai-settings',
  templateUrl: './open-ai-settings.component.html',
  styleUrls: ['./open-ai-settings.component.scss'],
})
export class OpenAiSettingsComponent implements OnInit {
  openAiSettingsForm: FormGroup;
  openAiSettingDtos: ConfigurationSettingsDto[] = [];
  genAITypes: GenericLookupDto[];
  openAiTypeSelected: boolean;

  private readonly settingKeyGenAIType = 'GenAI.Type';
  private readonly settingKeyModel = 'OpenAI.Model';
  private readonly settingKeyTemperature = 'OpenAI.Temperature';
  private readonly settingKeyMaximumNumberOfTokens = 'OpenAI.MaximumNumberOfTokens';
  private readonly settingKeyTopP = 'OpenAI.TopP';
  private readonly settingKeyFrequencyPenalty = 'OpenAI.FrequencyPenalty';
  private readonly settingKeyPresencePenalty = 'OpenAI.PresencePenalty';
  private readonly settingKeyPromptPrefix = 'OpenAI.PromptPrefix';
  private readonly settingKeyApiUri = 'OpenAI.ApiUri';
  private readonly settingKeyApiKey = 'OpenAI.ApiKey';
  private readonly settingKeyCustomizeSummarizationServiceEnabled =
    'OpenAI.CustomizeSummarizationServiceEnabled';
  private readonly settingKeyCustomizeSummarizationServiceApiUri =
    'OpenAI.CustomizeSummarizationServiceApiUri';
  private readonly settingKeyEnableTranscriptLogging = 'OpenAI.EnableTranscriptLogging';

  private readonly automaticSummarizationPrefix = 'AutomaticSummarization';
  private readonly automaticSummarizationEnabled = `${this.automaticSummarizationPrefix}.Enabled`;
  private readonly automaticSummarizationFilterConversations = `${this.automaticSummarizationPrefix}.FilterConversations`;
  private readonly automaticSummarizationCategories = `${this.automaticSummarizationPrefix}.Categories`;
  private readonly automaticSummarizationDailyConversationLimit = `${this.automaticSummarizationPrefix}.DailyConversationLimit`;

  private readonly defaultOpenAiApiUri = 'https://api.openai.com/v1/chat/completions';
  private readonly defaultAzureOpenAiApiUri =
    'https://{your-resource-name}.openai.azure.com/openai/deployments/{deployment-id}/chat/completions?api-version={api-version}';
  private originalApiUri = '';

  get hasSummarizationFeature(): boolean {
    return this.featureService.isEnabled(FeatureConstants.ConversationSummarization);
  }

  get customizeSummarizationServiceInfo(): SafeHtml | string {
    return this.localizationService.instant('Settings::CustomizeSummarizationServiceInfo');
  }

  constructor(
    private config: ConfigStateService,
    private fb: FormBuilder,
    private store: Store,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private crudService: CrudService,
    private operators: Operators,
    private featureService: FeatureService,
    private domSanitizer: DomSanitizer
  ) {
    this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(GenAiType)))
      .subscribe(result => {
        this.genAITypes = result;
      });

    let automaticSummarizationFilterConversations = this.config
      .getSetting(this.automaticSummarizationFilterConversations)
      ?.toLowerCase();

    automaticSummarizationFilterConversations =
      automaticSummarizationFilterConversations === null
        ? 'false'
        : automaticSummarizationFilterConversations;

    const numOfTokens = Number(this.config.getSetting(this.settingKeyMaximumNumberOfTokens));
    let genAIType = this.config.getSetting(this.settingKeyGenAIType);
    this.originalApiUri =
      this.isAzureOpenAiSelected(genAIType) || this.isPrivateLLMSelected(genAIType)
        ? this.config.getSetting(this.settingKeyApiUri)
        : this.defaultAzureOpenAiApiUri;

    this.openAiSettingsForm = fb.group({
      type: [this.config.getSetting(this.settingKeyGenAIType)],
      model: [this.config.getSetting(this.settingKeyModel)],
      temperature: [this.config.getSetting(this.settingKeyTemperature)],
      maximumNumberOfTokens: [
        numOfTokens > 0 ? numOfTokens : null,
        {
          validators: [Validators.required, Validators.min(1)],
        },
      ],
      topP: [this.config.getSetting(this.settingKeyTopP)],
      frequencyPenalty: [this.config.getSetting(this.settingKeyFrequencyPenalty)],
      presencePenalty: [this.config.getSetting(this.settingKeyPresencePenalty)],
      promptPrefix: [this.config.getSetting(this.settingKeyPromptPrefix)],
      apiUri: [this.originalApiUri],
      apiKey: [this.config.getSetting(this.settingKeyApiKey)],
      customizeSummarizationServiceEnabled: [
        JSON.parse(this.config.getSetting(this.settingKeyCustomizeSummarizationServiceEnabled).toLowerCase()),
      ],
      customizeSummarizationServiceUri: [
        this.config.getSetting(this.settingKeyCustomizeSummarizationServiceApiUri),
      ],
      enableTranscriptLogging: [
        JSON.parse(this.config.getSetting(this.settingKeyEnableTranscriptLogging).toLowerCase()),
      ],
      automaticSummarizationEnabled: [
        JSON.parse(this.config.getSetting(this.automaticSummarizationEnabled).toLowerCase()),
      ],
      automaticSummarizationFilterConversations: [automaticSummarizationFilterConversations],
      automaticSummarizationCategories: [null],
      automaticSummarizationDailyConversationLimit: [
        this.config.getSetting(this.automaticSummarizationDailyConversationLimit),
      ],
    });

    this.onTypeChange(true);
    this.loadCategories();
  }

  ngOnInit(): void {
    this.openAiSettingsForm
      .get('customizeSummarizationServiceEnabled')
      .valueChanges.subscribe(checked => {
        const uriControl = this.openAiSettingsForm.get('customizeSummarizationServiceUri');

        if (checked) {
          uriControl.setValidators([Validators.required]);
        } else {
          uriControl.clearValidators();
        }

        uriControl.updateValueAndValidity();
      });

    if (this.openAiSettingsForm.get('customizeSummarizationServiceEnabled').value) {
      this.openAiSettingsForm
        .get('customizeSummarizationServiceUri')
        .setValidators([Validators.required]);
      this.openAiSettingsForm.get('customizeSummarizationServiceUri').updateValueAndValidity();
    }
  }

  onSubmitOpenAiSettings() {
    if (this.openAiSettingsForm.invalid) {
      return;
    }

    this.openAiSettingDtos = new Array();

    this.openAiSettingDtos.push({
      settingName: this.settingKeyGenAIType,
      settingValue: this.openAiSettingsForm.get('type').value.toString(),
    });

    if (
      this.openAiSettingsForm.get('model').value &&
      this.openAiSettingsForm.get('model').enabled
    ) {
      this.openAiSettingDtos.push({
        settingName: this.settingKeyModel,
        settingValue: this.openAiSettingsForm.get('model').value.toString(),
      });
    }

    this.openAiSettingDtos.push({
      settingName: this.settingKeyTemperature,
      settingValue: this.openAiSettingsForm.get('temperature').value.toString(),
    });

    this.openAiSettingDtos.push({
      settingName: this.settingKeyMaximumNumberOfTokens,
      settingValue: this.openAiSettingsForm.get('maximumNumberOfTokens').value.toString(),
    });

    this.openAiSettingDtos.push({
      settingName: this.settingKeyTopP,
      settingValue: this.openAiSettingsForm.get('topP').value.toString(),
    });

    this.openAiSettingDtos.push({
      settingName: this.settingKeyFrequencyPenalty,
      settingValue: this.openAiSettingsForm.get('frequencyPenalty').value.toString(),
    });

    this.openAiSettingDtos.push({
      settingName: this.settingKeyPresencePenalty,
      settingValue: this.openAiSettingsForm.get('presencePenalty').value.toString(),
    });

    this.openAiSettingDtos.push({
      settingName: this.settingKeyApiUri,
      settingValue: this.openAiSettingsForm.get('apiUri').value.toString(),
    });

    if (this.openAiSettingsForm.get('apiKey').value) {
      this.openAiSettingDtos.push({
        settingName: this.settingKeyApiKey,
        settingValue: this.openAiSettingsForm.get('apiKey').value.toString(),
      });
    }

    this.openAiSettingDtos.push({
      settingName: this.settingKeyCustomizeSummarizationServiceEnabled,
      settingValue: this.openAiSettingsForm
        .get('customizeSummarizationServiceEnabled')
        .value.toString(),
    });

    if (this.openAiSettingsForm.get('customizeSummarizationServiceUri').value) {
      this.openAiSettingDtos.push({
        settingName: this.settingKeyCustomizeSummarizationServiceApiUri,
        settingValue: this.openAiSettingsForm
          .get('customizeSummarizationServiceUri')
          .value.toString(),
      });
    }

    this.openAiSettingDtos.push({
      settingName: this.settingKeyEnableTranscriptLogging,
      settingValue: this.openAiSettingsForm.get('enableTranscriptLogging').value.toString(),
    });

    if (
      this.hasSummarizationFeature &&
      this.openAiSettingsForm.get('automaticSummarizationEnabled').value
    ) {
      this.openAiSettingDtos.push({
        settingName: this.automaticSummarizationEnabled,
        settingValue: this.openAiSettingsForm.get('automaticSummarizationEnabled').value.toString(),
      });

      this.openAiSettingDtos.push({
        settingName: this.automaticSummarizationFilterConversations,
        settingValue: this.openAiSettingsForm.get('automaticSummarizationFilterConversations')
          .value,
      });

      this.openAiSettingDtos.push({
        settingName: this.automaticSummarizationCategories,
        settingValue: JSON.stringify(
          this.openAiSettingsForm.get('automaticSummarizationCategories').value?.map(x => x.id)
        ),
      });

      this.openAiSettingDtos.push({
        settingName: this.automaticSummarizationDailyConversationLimit,
        settingValue: this.openAiSettingsForm
          .get('automaticSummarizationDailyConversationLimit')
          .value.toString(),
      });

      this.openAiSettingDtos.push({
        settingName: this.settingKeyPromptPrefix,
        settingValue: this.openAiSettingsForm.get('promptPrefix').value.toString(),
      });
    }

    this.settingService.saveSetting(this.openAiSettingDtos).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });
  }

  onTypeChange(initial: boolean = false) {
    if (this.isAzureOpenAiSelected()) {
      this.openAiSettingsForm.get('model').disable();
      this.openAiSettingsForm.get('apiUri').patchValue(this.originalApiUri);
      this.openAiTypeSelected = false;
    } else if (this.isPrivateLLMSelected()) {
      this.openAiSettingsForm.get('model').enable();
      this.openAiTypeSelected = false;
      if (!initial) {
        this.openAiSettingsForm.get('apiUri').patchValue('');
        this.openAiSettingsForm.get('model').patchValue('');
      }
    } else {
      this.openAiSettingsForm.get('model').enable();
      this.openAiSettingsForm.get('apiUri').patchValue(this.defaultOpenAiApiUri);
      this.openAiTypeSelected = true;
    }
  }

  isPrivateLLMSelected(type = this.openAiSettingsForm.get('type').value): boolean {
    return (
      type.toString().toLowerCase() ===
      this.genAITypes.find(x => x.id === GenAiType.privateLLM)?.code.toLowerCase()
    );
  }

  private isAzureOpenAiSelected(type = this.openAiSettingsForm.get('type').value): boolean {
    return (
      type.toString().toLowerCase() ===
      this.genAITypes.find(x => x.id === GenAiType.azureOpenAI)?.code.toLowerCase()
    );
  }

  private loadCategories(): void {
    const categorySettingValue = this.config.getSetting(this.automaticSummarizationCategories);
    const categoryIds = JSON.parse(categorySettingValue) as string[];

    if (categoryIds && categoryIds.length > 0) {
      this.crudService
        .get<CategoryDto>(CategoryDto, {
          filters: [
            {
              field: 'id',
              operator: this.operators.In,
              value: categoryIds,
            },
          ],
          sorters: [],
          skipCount: 0,
          maxResultCount: 9999,
        })
        .subscribe(response => {
          this.openAiSettingsForm.get('automaticSummarizationCategories').setValue(response.items);
        });
    }
  }
}
